import React from "react";

function LogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="155"
      height="32"
      fill="none"
      viewBox="0 0 155 32"
    >
      <path
        fill="#E94B57"
        d="M17.785 11.484h-.08c-1.287-2.022-3.813-3.09-6.544-3.09-6.381 0-11.16 5.23-11.16 11.727C0 26.617 4.574 31.89 11.16 31.89c2.931 0 5.58-1.19 6.544-3.133h.08v2.38h6.667V9.147h-6.667v2.337zm-5.58 14.267a5.534 5.534 0 01-5.542-5.55c0-3.053 2.41-5.668 5.461-5.668 3.05 0 5.661 2.497 5.661 5.588 0 3.09-2.449 5.626-5.58 5.626v.004zM41.047 21.39c0 2.695-1.286 4.36-3.813 4.36-2.526 0-3.574-1.345-3.574-4.242V9.184h-6.666v13.674c0 6.26 3.893 9.035 8.592 9.035 3.012 0 4.78-1.03 5.742-3.052h.081v2.338h6.305V9.184h-6.667V21.39zM125.468 11.522h-.081V9.184h-6.262V31.18h6.667V19.053c0-2.498 1.286-3.965 5.261-4.003V8.432c-2.569 0-4.418.752-5.581 3.09h-.004zM147.971 22.698c-.866 1.764-2.623 3.03-4.806 3.03-2.943 0-5.319-2.543-5.319-5.565 0-3.022 2.338-5.565 5.357-5.565 2.199 0 3.948 1.288 4.795 3.071h6.99c-1.14-5.527-5.927-9.233-11.785-9.233-6.809 0-12.047 4.972-12.047 11.73 0 6.76 5.357 11.732 12.047 11.732 5.873 0 10.672-3.79 11.797-9.196h-7.029v-.004zM59.553 3.74h-6.686v5.44h-3.462v5.8h3.462v8.208c0 5.622 3.986 8.367 8.9 8.367 1.772 0 3.012-.179 3.987-.441V25.18c-.709.266-1.418.4-2.434.4-2.658 0-3.767-1.107-3.767-3.764V14.98h6.2V9.18h-6.2V3.74zM96.395 25.766a5.616 5.616 0 01-4.16-1.836c-.6 2.11-1.698 4.01-3.162 5.576A11.953 11.953 0 0096.395 32h.007c.385 0 .763-.023 1.136-.057.127-.011.25-.034.374-.05.246-.03.497-.064.74-.11.146-.026.288-.06.43-.095a11.39 11.39 0 001.684-.513c.146-.057.296-.114.443-.178.188-.084.369-.171.55-.263.143-.072.289-.14.428-.216.177-.1.35-.206.52-.312.219-.137.435-.277.647-.43.185-.133.374-.266.551-.406.161-.13.315-.274.47-.41.104-.096.211-.183.311-.282a19.46 19.46 0 00.717-.753c.146-.167.289-.342.423-.517.074-.095.147-.19.216-.285.139-.186.274-.38.397-.574l.162-.262c.131-.213.258-.43.373-.65.031-.053.054-.114.081-.168.424-.836.751-1.73.963-2.664h-6.667a5.618 5.618 0 01-4.964 2.969l.008-.008z"
      ></path>
      <path
        fill="#E94B57"
        d="M107.571 16.156c-.046-.122-.084-.247-.135-.365a10.46 10.46 0 00-.261-.57c-.066-.134-.128-.27-.197-.4a8.433 8.433 0 00-.296-.52c-.081-.133-.162-.27-.247-.4a11.14 11.14 0 00-.624-.87 13.263 13.263 0 00-.354-.437c-.112-.13-.231-.255-.351-.38-.123-.13-.242-.263-.373-.388-.146-.14-.297-.27-.451-.403-.115-.099-.223-.205-.343-.3a11.7 11.7 0 00-.828-.612c-.115-.076-.234-.145-.35-.217-.189-.118-.377-.236-.574-.342-.131-.072-.269-.137-.404-.206-.189-.095-.382-.19-.578-.273-.139-.061-.285-.118-.427-.175-.201-.08-.401-.152-.609-.22a8.097 8.097 0 00-.435-.137c-.216-.061-.435-.118-.655-.168-.142-.034-.281-.068-.427-.095-.247-.045-.497-.08-.748-.114-.123-.015-.246-.038-.37-.05a11.827 11.827 0 00-3.55.182 11.909 11.909 0 00-2.95 1.012 12.454 12.454 0 00-1.968 1.24c-.39.3-.763.612-1.11.954-.115.114-.223.243-.335.361-.404.433-.785.89-1.12 1.38-.305.445-.578.912-.817 1.395-.011.027-.03.053-.042.08-.212.437-.393.89-.55 1.353-.04.114-.082.228-.116.342-.3.985-.47 2.023-.501 3.098 0 .092-.008.22-.012.32 0 1.418-.55 2.71-1.448 3.687a5.616 5.616 0 01-4.16 1.836c-3.092 0-5.607-2.482-5.607-5.535 0-3.052 2.515-5.535 5.608-5.535 1.652 0 3.131.711 4.16 1.836a13.64 13.64 0 013.161-5.576 11.952 11.952 0 00-7.321-2.494 11.966 11.966 0 00-9.886 5.19 11.614 11.614 0 00-2.033 6.58c-.001.813.084 1.603.242 2.371.158.768.392 1.506.693 2.209a11.764 11.764 0 002.557 3.74 11.824 11.824 0 003.262 2.285c.52.247 1.063.46 1.626.631 1.12.342 2.31.529 3.547.529 1.236 0 2.426-.187 3.547-.529a12.15 12.15 0 001.625-.63c.52-.248 1.02-.533 1.498-.849.227-.152.44-.319.655-.482.389-.3.762-.612 1.109-.954l.335-.362a11.817 11.817 0 002.222-3.38c.597-1.394.928-2.926.936-4.534a5.587 5.587 0 015.608-5.58c2.191 0 4.09 1.25 5.01 3.063h6.648l-.008-.026a11.356 11.356 0 00-.474-1.578l-.004.012zM116.591 9.18h-6.663v22.014h6.663V9.18zM113.175 0c-2.014 0-3.713 1.638-3.713 3.665 0 2.026 1.699 3.664 3.713 3.664 2.014 0 3.751-1.638 3.751-3.664 0-2.027-1.66-3.665-3.751-3.665z"
      ></path>
    </svg>
  );
}

export default LogoIcon;
