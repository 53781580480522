import { useState } from "react";
import styles from "./Header.module.scss";
import MenuIcon from "../Assets/Svg/MenuIcon.jsx";
import LogoIcon from "../Assets/Svg/LogoIcon.jsx";
import { MenuComponent } from "./Menu/MenuComponent.jsx";
import { MobileMenuComponent } from "./Menu/MobileMenuComponent.jsx";
import DownCaret from "../Assets/Svg/DownCaret.jsx";


const LocaleSelector = ({ locales, currentLocale }) => {
  const [open, setOpen] = useState(false);

  if (!locales || !currentLocale) {
    console.error(
      "Content is undefined in Header LocaleSelector, returning nothing to UI. Try adding content to the block."
    );
    return null;
  }

  const localeNames = {
    sv: "Svenska",
    en: "English",
    nb: "Norsk",
    it: "Italiano",
    fi: "Suomi",
    fr: "Français",
    pl: "Polska",
    "": "English",
  };

  const localeUrls = {
    sv: "/",
    en: "https://autocirc.com/",
    nb: "https://autocirc.com/no",
    it: "https://autocirc.com/it",
    fi: "https://autocirc.com/fi",
    fr: "https://autocirc.com/fr",
    pl: "/pl",
    "": "https://autocirc.com/",
  };


  const updatedLocales = locales.map((locale) => {


    return {
      ...locale,
      name: localeNames[locale.code] || locale.name,
      url: localeUrls[locale.code] || locale.url,
    };
  }).filter((locale) => locale?.code !=='pl');

  return (
    <div
      className={styles.localesWrapper}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className={styles.currentLocaleWrapper}>
        <p>{currentLocale}</p>
        <span>
          <DownCaret />
        </span>
      </div>
      {open && (
        <div className={styles.localesMenu}>
          <div className={styles.localesMenuHeader}>
            <p>Choose language</p>
          </div>
          {updatedLocales
            .filter((locale) => locale?.code !== currentLocale)
            .map((locale) => (
              <a href={locale?.url} key={locale?.code}>
                {locale?.name}
              </a>
            ))}
        </div>
      )}
      {open && (
        <div
          onClick={() => setOpen(false)}
          className={styles.localesMenuOverlay}
        />
      )}
    </div>
  );
};

export const HeaderComponent = ({ locales = [], currentLocale = 'en', menuLinks = [] }) => {
  const [scrollY, setScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  if (!menuLinks) {
    console.error(
      "Content is undefined in Header HeaderComponent MenuItems, returning nothing to UI. Try adding content to the block."
    );
    return null;
  } else if (!locales || !currentLocale) {
    console.error(
      "Content is undefined in Header HeaderComponent Locales, returning nothing to UI. Try adding content to the block."
    );
    return null;
  }


  function buildHierarchy(data) {
    const items = JSON.parse(JSON.stringify(data));
    let itemMap;

    if (Array.isArray(items)) {
        itemMap = new Map(items.map((item) => [item.id, item]));
    }

    const topLevelItems = [];

    if (Array.isArray(items)) {
        (items || []).forEach((item) => {
            if (item.parent) {
                const parent = itemMap.get(item.parent.id);
                if (parent) {
                    if (!parent.items) {
                        parent.items = [];
                    }
                    parent.items.push(item);
                }
            } else {
                topLevelItems.push(item);
            }
        });
    }

    return topLevelItems;
  }

  const hierarchicalData = buildHierarchy(menuLinks);

  return (

      <>
      <div className={styles.logo}>
        <a href={`/${currentLocale}`}>
          <LogoIcon />
        </a>
      </div>
      <div className={styles.linksWrapper}>
        <div className={styles.links}>


        {
            Array.isArray(menuLinks) && menuLinks.map((link) => {
                if (link.menuAttached) {
                    return (
                        <a href={`/${currentLocale}/${link.path}`} key={link.title}>
                        {link.title}
                        </a>
                    );
                } else {
                    return null;
                }
            })
        }


        </div>
        {locales && (
          <LocaleSelector locales={locales} currentLocale={currentLocale} />
        )}
      </div>

      <div>
        <div
          className={`${styles.icon} ${menuOpen && styles.iconInverted}`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <MenuIcon />
        </div>
      </div>
      <div className={styles.desktopMenuWrapper}>
        <MenuComponent
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          hierarchicalData={hierarchicalData}
          currentLocale={currentLocale}
        />
      </div>
      <div className={styles.mobileMenuWrapper}>
        <MobileMenuComponent
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          hierarchicalData={hierarchicalData}
          currentLocale={currentLocale}
        />
      </div>
      </>

  );
};
