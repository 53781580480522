import React, { useEffect, useState } from "react";
import styles from "./MobileMenu.module.scss";
import ExternalLinkIcon from "../../Assets/Svg/ExternalLinkIcon";
import BackArrowIcon from "../../Assets/Svg/BackArrowIcon";
import { MenuFooterComponent } from "./MenuFooterComponent";

export const MobileMenuComponent = ({
  menuOpen,
  setMenuOpen,
  hierarchicalData,
  currentLocale,
}) => {
  const [levelTwoOpen, setLevelTwoOpen] = useState(false);
  const [levelThreeOpen, setLevelThreeOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  useEffect(() => {
    if (!menuOpen) {
      setTimeout(() => {
        setLevelTwoOpen(false);
        setLevelThreeOpen(false);
        setActiveCategory(null);
        setActiveSubCategory(null);
      }, 500);
    }
  }, [menuOpen]);

  return (
    <div
      className={`${styles.mobileMenu} ${styles.mobileMenuLevelOne} ${
        menuOpen ? "open" : styles.mobileMenuLevelOneClosed
      }`}
    >
      <div
        className={`${styles.mobileMenuWrapper} ${
          levelTwoOpen && styles.mobileMenuWrapperLevelTwoOpen
        }
        ${levelThreeOpen && styles.mobileMenuWrapperLevelThreeOpen}
      }`}
      >
        <div style={{ marginTop: "80px" }}>
          {hierarchicalData?.map((link, index) =>
            link?.items?.length > 0 ? (
              <div
                key={link?.title + index}
                className={styles.mobileMenuLink}
                onClick={() => [setLevelTwoOpen(true), setActiveCategory(link)]}
              >
                <p>{link?.title}</p>
                <span>+</span>
              </div>
            ) : (
              <div className={styles.mobileMenuLink} key={link.title + index}>
                {link?.externalPath ? (
                  <a
                    href={link?.externalPath}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link?.title}
                  </a>
                ) : (
                  <a href={`/${currentLocale}/${link?.path}`}>{link?.title}</a>
                )}
                {link?.type === "EXTERNAL" && <ExternalLinkIcon />}
              </div>
            )
          )}
        </div>
        <div>
          <div
            className={styles.backButton}
            onClick={() => setLevelTwoOpen(false)}
          >
            {menuOpen && activeCategory && <BackArrowIcon />}
          </div>

          {menuOpen && activeCategory && (
            <div className={styles.mobileSubMenuLink}>
              <a href={`/${currentLocale}/${activeCategory?.path}`}>
                {activeCategory?.title}
              </a>
            </div>
          )}

          {menuOpen &&
            activeCategory &&
            activeCategory?.items.map((link, index) =>
              link?.items?.length > 0 ? (
                <div
                  key={link?.title + index}
                  className={styles.mobileSubMenuLink}
                  onClick={() => [
                    setLevelThreeOpen(true),
                    setActiveSubCategory(link),
                  ]}
                >
                  <p>{link?.title}</p>
                  <span>+</span>
                </div>
              ) : (
                <div
                  className={styles.mobileSubMenuLink}
                  key={link?.title + index}
                >
                  {link?.externalPath ? (
                    <a
                      href={link?.externalPath}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link?.title}
                    </a>
                  ) : (
                    <a href={`/${currentLocale}/${link?.path}`}>
                      {link?.title}
                    </a>
                  )}
                  {link?.type === "EXTERNAL" && <ExternalLinkIcon />}
                </div>
              )
            )}
        </div>

        <div>
          <div
            className={styles.backButton}
            onClick={() => [setLevelThreeOpen(false), setLevelTwoOpen(true)]}
          >
            {menuOpen && activeSubCategory && <BackArrowIcon />}
          </div>

          {menuOpen && activeSubCategory && (
            <div className={styles.mobileSubMenuLink}>
              <a href={`/${currentLocale}/${activeSubCategory?.path}`}>
                {activeSubCategory?.title}
              </a>
            </div>
          )}

          {menuOpen &&
            activeSubCategory?.items.map((link, index) =>
              link?.items?.length > 0 ? (
                <div
                  key={link?.title + index}
                  className={styles.mobileSubMenuLink}
                  onClick={() => [
                    setLevelThreeOpen(true),
                    setActiveSubCategory(link),
                  ]}
                >
                  <p>{link?.title}</p>
                  <span>+</span>
                </div>
              ) : (
                <div
                  className={styles.mobileSubMenuLink}
                  key={link?.title + index}
                >
                  {link?.externalPath ? (
                    <a
                      href={link?.externalPath}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link?.title}
                    </a>
                  ) : (
                    <a href={`/${currentLocale}/${link?.path}`}>
                      {link?.title}
                    </a>
                  )}
                  {link?.type === "EXTERNAL" && <ExternalLinkIcon />}
                </div>
              )
            )}
        </div>
        <MenuFooterComponent />
      </div>
    </div>
  );
};
