import React from "react";

function BackArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g className="nc-icon-wrapper" fill="#ffffff">
        <path
          d="M8.293,4.293l-7,7a1,1,0,0,0,0,1.414l7,7a1,1,0,0,0,1.414-1.414L4.414,13H22a1,1,0,0,0,0-2H4.414L9.707,5.707A1,1,0,1,0,8.293,4.293Z"
          fill="#ffffff"
        ></path>
      </g>
    </svg>
  );
}

export default BackArrowIcon;
