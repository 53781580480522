import React from "react";

function MenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 5h2M1 5h16M3 12H1M23 12H7M21 19h2M1 19h16"
      ></path>
    </svg>
  );
}

export default MenuIcon;