import React from "react";
import styles from "./MenuFooter.module.scss";
import LinkedInLogo from "../../Assets/Svg/LinkedInLogo";

export const MenuFooterComponent = () => {
  return (
    <div className={styles.menuFooter}>
      <div>
        <p>Autocirc AB</p>
        <p>Österlånggatan 69</p>
        <p>503 37 Borås</p>
      </div>
      <div>
        <a href="mailto:info@autocirc.com">info@autocirc.com</a>
      </div>
      <div>
        <LinkedInLogo />
      </div>
    </div>
  );
};
