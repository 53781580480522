import React, { useEffect, useState } from "react";
import styles from "./Menu.module.scss";
import ExternalLinkIcon from "../../Assets/Svg/ExternalLinkIcon";
import { MenuFooterComponent } from "./MenuFooterComponent";

export const MenuComponent = ({
  menuOpen,
  setMenuOpen,
  hierarchicalData,
  currentLocale,
}) => {
  const [levelTwoOpen, setLevelTwoOpen] = useState(false);
  const [levelThreeOpen, setLevelThreeOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  useEffect(() => {
    if (!menuOpen) {
      setTimeout(() => {
        setLevelTwoOpen(false);
        setLevelThreeOpen(false);
        setActiveCategory(null);
        setActiveSubCategory(null);
      }, 500);
    }
  }, [menuOpen]);

  return (
    <>
      <div
        className={`${styles.menu} ${styles.menuLevelOne} ${
          menuOpen ? "open" : styles.menuLevelOneClosed
        }`}
      >
        <div>
          {hierarchicalData.map((link, index) =>
            link?.items ? (
              <div
                key={link?.title + index}
                className={`
                  ${styles.menuLink} ${
                    link?.id === activeCategory?.id && styles.menuLinkActive
                  } 
                  `}
                onClick={() => [
                  setLevelTwoOpen(true),
                  setLevelThreeOpen(false),
                  setActiveCategory(link),
                ]}
              >
                <p>{link?.title}</p>
                <span>+</span>
              </div>
            ) : (
              <div className={styles.menuLink} key={link?.title + index}>
                {link?.externalPath ? (
                  <a
                    href={`/${currentLocale}/${link?.externalPath}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link?.title}
                  </a>
                ) : (
                  <a href={`/${currentLocale}/${link?.path}`}>{link?.title}</a>
                )}
                {link?.type === "EXTERNAL" && <ExternalLinkIcon />}
              </div>
            )
          )}
        </div>
        <MenuFooterComponent />
      </div>

      <div
        className={`${styles.menu} ${styles.menuLevelTwo} ${
          levelTwoOpen ? "open" : styles.menuLevelTwoClosed
        } ${!menuOpen && styles.menuLevelTwoClosed} `}
      >
        {activeCategory && (
          <div className={styles.subMenuLink}>
            <a href={`/${currentLocale}/${activeCategory?.path}`}>
              {activeCategory?.title}
            </a>
          </div>
        )}

        {activeCategory?.items.map((link, index) =>
          link?.items?.length > 0 ? (
            <>
              <div
                key={link?.title + index}
                className={`
                ${styles.subMenuLink} ${
                  link?.id === activeSubCategory?.id && styles.menuLinkActive
                } 
                `}
                onClick={() => [
                  setLevelThreeOpen(true),
                  setActiveSubCategory(link),
                ]}
              >
                <p>{link?.title}</p>
                <span>+</span>
              </div>
            </>
          ) : (
            <div className={styles.subMenuLink} key={link?.title + index}>
              {link?.externalPath ? (
                <a
                  href={link?.externalPath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link?.title}
                </a>
              ) : (
                <a href={`/${currentLocale}/${link?.path}`}>{link?.title}</a>
              )}
              {link?.type === "EXTERNAL" && <ExternalLinkIcon />}
            </div>
          )
        )}
      </div>

      <div
        className={`${styles.menu} ${styles.menuLevelThree}
    ${levelThreeOpen ? "open" : styles.menuLevelThreeClosed} ${
      !menuOpen && styles.menuLevelThreeClosed
    }`}
      >
        {activeSubCategory && (
          <div className={styles.subMenuLink}>
            <a href={`/${currentLocale}/${activeSubCategory?.path}`}>
              {activeSubCategory?.title}
            </a>
          </div>
        )}

        {activeSubCategory?.items.map((link, index) =>
          link?.items?.length > 0 ? (
            <>
              <div
                key={link?.name + index}
                className={styles.subMenuLink}
                onClick={() => [
                  setLevelThreeOpen(true),
                  setActiveSubCategory(link),
                ]}
              >
                <p>{link?.title}</p>
                <span>+</span>
              </div>
            </>
          ) : (
            <div className={styles.subMenuLink} key={link?.title + index}>
              {link?.externalPath ? (
                <a
                  href={link?.externalPath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link?.title}
                </a>
              ) : (
                <a href={`/${currentLocale}/${link?.path}`}>{link?.title}</a>
              )}
              {link?.type === "EXTERNAL" && <ExternalLinkIcon />}
            </div>
          )
        )}
      </div>

      <div
        className={`${styles.background} ${
          menuOpen ? styles.backgroundOpen : ""
        }`}
        onClick={() => setMenuOpen(false)}
      />
    </>
  );
};
